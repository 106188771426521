<template>
  <v-chart class="chart" :option="option" />
</template>
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { FunnelChart } from "echarts/charts";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
use([
  CanvasRenderer,
  FunnelChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);
export default {
  props: ["data"],
  data() {
    return {
      timer: 0,
      option: {
        series: {
          name: "平台转化模型",
          type: "funnel",
          width: "70%",
          top: '5%',
          label: {
            position: 'right',
            fontSize: 20
          },
          data: [
            { value: 5, name: '326人\r\n推荐人数' },
            { value: 30, name: '2330人\r\n转化人数' },
            { value: 60, name: '7210人\r\n意向人数' },
            { value: 80, name: '12130人\r\n会员月活跃人数' },
            { value: 100, name: '27260人\r\n会员总人数' }
          ]
        }
      }
    }
  },
  mounted() {
  }
};
</script>

<style scoped>
</style>
