<template>
  <div class="home">
    <div class="flex">
      <div class="canvas" style="opacity: 0.1">
        <iframe
          frameborder="0"
          src="static/index.html"
          style="width: 100%; height: 100%"
        ></iframe>
      </div>
      <div class="item">
        <cloumn-item class="item-box box01" :title="`会员概况`">
          <zhan-shi></zhan-shi>
        </cloumn-item>
        <cloumn-item class="item-box box02" :title="`小程序会员来源地分布`">
          <gai-kuang></gai-kuang>
        </cloumn-item>
      </div>
      <div class="item">
        <cloumn-item class="item-box box05" :title="`平台漏斗转化模型`">
          <Funnel />
        </cloumn-item>
      </div>
      <div class="item">
        <cloumn-item class="item-box box03" :title="`优惠券领取对比分析`">
          <Coupon />
        </cloumn-item>
        <cloumn-item class="item-box box04" :title="`活动对比新增员情况`">
          <Activites />
        </cloumn-item>
      </div>
    </div>
  </div>
</template>

<script>
import cloumnItem from "@/components/home/cloumnItem";
import ZhanShi from "@/components/jingDianGuanLi/zhanShi.vue";
import GaiKuang from "@/components/jingDianGuanLi/gaiKuang.vue";
import Coupon from "@/components/jingDianGuanLi/coupon.vue";
import Activites from "@/components/jingDianGuanLi/activities.vue";
import Funnel from "@/components/jingDianGuanLi/funnel.vue";

export default {
  // name: "Home",
  components: {
    cloumnItem,
    ZhanShi,
    GaiKuang,
    Coupon,
    Activites,
    Funnel
  },
  data() {
    return {
      data: {},
      timer: 0,
    };
  },
  methods: {
    getData() {
    },
  },
  mounted() {
    this.timer = setInterval(this.getData, 10000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
/**
 * The default size is 600px×400px, for responsive charts
 * you may need to set percentage values as follows (also
 * don't forget to provide a size for the container).
 */
/* .echarts {
  width: 100%;
  height: 100%;
} */
.canvas {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 99%;
}

.flex {
  align-items: start;
  justify-content: space-between;
}

.item-box {
  margin-bottom: 28px;
  width: 450px;
}

.item-box.box04 {
  width: 480px;
  height: 300px;
}

.item-box.box05 {
  width: 800px;
  height: 620px;
}

.item-box.box03 {
  height: 300px;
}

/* .item-box.box01{height:287px} */
</style>
