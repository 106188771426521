<template>
  <v-chart class="chart" :option="option" />
</template>
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";

import { getCouponUsage } from "@/api/api.js";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);
export default {
  props: ["data"],
  data() {
    return {
      timer: 0,
      option: {
        title: {
          text: "11%/35%",
          subtext: '领取会员占比',
          show: true,
          left:'center',
          top:'40%',
          textStyle:{
            fontSize: 20,
            color:'#ffffff',
            align:'center'
          },
          subtextStyle:{
            fontSize: 12,
            color:'#ffffff',
          }
        },
        tooltip: {
          trigger: "item",
        },
        series: {
          name: "本月优惠券情况",
          type: "pie",
          radius: [80, 40],
          center: ["50%", "50%"],
          roseType: "area",
          data: this.newData,
          left: "0%",
          itemStyle: {
            normal: {
              label: {
                show: true,
                formatter: '{c}% \n\r {b}'
              }
            }
          },
        },
      },
    };
  },
  methods: {
    getData() {
      getCouponUsage().then((res) => {
        const data = res.data.data
        this.option.series.data = [
          {name: '本月优惠券领取数量', value: data.current_month_voucher_receive_count},
          {name: '本月优惠券使用数量', value: data.current_month_voucher_verify_count	},
        ]
      });
    }
  },
  mounted() {
    // this.getData()
    this.option.series.data = [
      {name: '商户总数', value: 75.8},
      {name: '参与商户总数', value: 24.2 },
    ]
    // this.timer = setInterval(this.getData, 6000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
};
</script>

<style scoped>
</style>
