<template>
  <v-chart class="chart" :option="option" />
</template>
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";

import { getCouponUsage } from "@/api/api.js";
import Mock from 'mockjs'

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);
export default {
  props: ["data"],
  data() {
    return {
      timer: 0,
      option: {
        legend: {
          type: "plain",
          orient: "vertical",
          right: "10%",
          top: "20%",
          textStyle: {
            color: "#a3fffe",
            fontSize: "16",
          },
          itemHeight: 12,
          itemWidth: 12,
          itemGap: 20,
          formatter: function(name) {
            if(name == "本月活动情况"){
              return "本月活动情况"
            } else if (name == "活动人数") {
              return `${name}: ${Mock.Random.natural(60, 100)}`
            } else {
              return `${name}: ${Mock.Random.natural(120, 500)}`
            }
            
          }
        },
        tooltip: {
          trigger: "item",
        },
        series: {
          name: "本月活动情况",
          type: "pie",
          radius: ['30%', '50%'],
          center: ["30%", "50%"],
          roseType: "radius",
          data: this.newData,
          left: "10%",
          itemStyle: {
            normal: {
              label: {
                show: true,
                formatter: '{c}% \n\r {b}'
              }
            }
          },
        },
      },
    };
  },
  methods: {
    getData() {
      getCouponUsage().then((res) => {
        const data = res.data.data
        this.option.series.data = [
          {name: '本月优惠券领取数量', value: data.current_month_voucher_receive_count},
          {name: '本月优惠券使用数量', value: data.current_month_voucher_verify_count	},
        ]
      });
    }
  },
  mounted() {
    // this.getData()
    this.option.series.data = [
      {name: '会员数', value: 85.11},
      {name: '活动人数', value: 14.89 },
    ]
    // this.timer = setInterval(this.getData, 6000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
};
</script>

<style scoped>
</style>
